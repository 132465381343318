/* .table{
    width: 100%;
    height: max-content;
    max-height: 60vh;
    overflow-y: scroll;
}

.td{
    border: 1px solid #363636;
    text-align: center;
    padding: 0.5rem;
    border-radius: 2px;
}
tr:nth-child(odd){
    background-color: #edf8ff;
}
th{
    border: 1px solid #363636;
    padding: 0.5rem;
    border-radius: 2px;
    position: sticky;
    top: 0;

} */
 .website-query-heading{
  text-align: center;
  font-size: 1.4rem;
  color: #424242;
  padding: 1rem 0px;
 }

.tableHeader {
  background-color: #dedcdc;
  position: sticky;
  top: 0;
}

.tableData {
  color: #424242;
}

.delButton {
  color: #eb3838;
}

.tableContect {
  max-height: 65vh;
  overflow-y: scroll;
}
.tableContect::-webkit-scrollbar {
  display: none;
}
.table{
  /* height: 50vh; */
  overflow-y: scroll
  
}
.table::-webkit-scrollbar{
  /* display: none; */
}