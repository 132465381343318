.blog-content-section {
  background-color: #fff;
  /* margin: 1rem 0px 3rem; */
  position: relative;
  width: 80%;
  margin-inline: auto;

      width: 80%;
    background-color: #fff;
    padding: 2% 4% 6%;
    border-radius: 5px;
    margin-inline: auto;
  font-family: "Readex Pro" !important;

}
.blog-content-wrapper {
  font-family: "Readex Pro" !important;
}
.blog-content-wrapper p {
  font-size: 1rem !important;
  color: #636060 !important;
}
.blog-content-wrapper h1,
.blog-content-wrapper h2,
.blog-content-wrapper h3,
.blog-content-wrapper h4,
.blog-content-wrapper h5,
.blog-content-wrapper h6 {
  font-family: var(--font-monst) !important;
  color: #272626 !important;
}
.blog-content-wrapper a {
  color: var(--primaryBlue) !important;
    font-family: "Readex Pro" !important;

}
.blog-content-wrapper a:hover{
  text-decoration: underline;
}
.blog-content-wrapper img {
  width: 100%;
  /* height: 30vh; */
  object-fit: contain;
}
.blog-content-wrapper video {
  /* width: 100%; */
  object-fit: contain;
}

.back-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 2%;
  right: 100%;
}

.back-btn {
  margin: 0 !important;
  background-color: transparent !important;
  /* position: sticky; */
  top: 0;
}
.blog-content-wrapper {
  width: 100%;
  height: max-content !important;
  max-height: unset;
  min-height: unset;
  /* overflow: hidden; */
}
.blog-date {
  color: #716d6d;
  font-weight: 300;
  font-size: 0.9rem;
  margin: 1rem 0px;
}
.blog-heading {
  font-weight: 600;
  font-size: 1.3rem;
  color: #272626;
  margin: 1rem 0px;
}
.blog-img-wrapper {
  width: 100%;
    /* height: 50vh; */

  margin-bottom: 1%;
  
}
.blog-img-wrapper img {
  width: 100%;
  /* height: 100%; */
  /* height: 100%; */
  object-fit: contain;
}

/* blog text styles */
.blog-heading {
  font-weight: 500;
  color: #636060;
  margin: 1rem 0px;
  line-height: 2rem;
}
.blog-paragraph {
  font-weight: 300;
  color: #636060;
  line-height: 1.5rem;
  text-align: justify;
    font-family: "Readex Pro" !important;

}
.blog-date {
  color: #716d6d;
  font-weight: 300;
  font-size: 0.9rem;
  margin: 1rem 0px;
}
.blog-heading {
  /* font-weight: 600; */
  font-size: 1.3rem;
  /* color: #272626; */
  margin: 1rem 0px;
}
.blog-img-wrapper {
  width: 100%;
    /* height: 50vh; */

  margin-bottom: 1%;
  
}
.blog-img-wrapper img {
  width: 100%;
  /* height: 100%; */
  /* height: 100%; */
  object-fit: contain;
}
.back-btn {
  margin: 0 !important;
  background-color: transparent !important;
  /* position: sticky; */
  top: 0;
    width: max-content;
  margin-inline: auto;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #272626a2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.back-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 2%;
  right: 5%;
}

@media screen and (max-width: 1000px) {
  .blog-date {
    margin-top: 0rem;
  }
}
@media screen and (max-width: 900px) {
  .blog-content-wrapper p {
    font-size: 0.9rem !important;
  }
}
@media screen and (max-width: 600px) {
  .blog-content-wrapper p {
    font-size: 0.83rem !important;
  }
  .blog-content-wrapper li,.blog-content-wrapper ol {
    font-size: 0.83rem !important;
  }

  .blog-content-wrapper h1 {
    font-size: 1.3rem;
  }
  .blog-content-wrapper h2 {
    font-size: 1.1rem;
  }
  .blog-content-wrapper h3 {
    font-size: 1rem;
  }
  .blog-content-wrapper h4 {
    font-size: 0.9rem;
  }
  .blog-content-wrapper h5 {
    font-size: 0.8rem;
  }
  .blog-content-wrapper h6 {
    font-size: 0.7rem;
  }
  .blog-content-wrapper img{
    width: 100%;
    object-fit: contain;
  }
}
