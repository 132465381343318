.main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
}
.pagination-wrapper {
  margin-inline: auto;
  position: sticky;
  bottom: 0%;
  width: 100%;
  overflow: hidden;
}
.blog-card {
  width: 32%;
  min-width: 32%;
  position: relative;
}
.news-letter-heading {
  font-weight: 800;
  font-size: 1.7rem !important;
  padding: 1% 0px !important;
}
.blog-card-wrapper {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
  width: 100%;
  margin-inline: auto;
  height: 90%;
  padding: 3% 0px;
  overflow-y: scroll;
}
.file-input {
  display: none;
  /* width: 100%; */
}
.for-center {
  display: flex;
  justify-content: center;
}
.banner-img-wrapper {
  width: 100%;
}
.banner-img-wrapper img {
  width: 100%;
  object-fit: contain;
}
.select-img-name {
  padding: 0.3rem 1rem;
  background-color: rgba(0, 0, 255, 0.479);
  color: #fff;
  border-radius: 5px;
  margin-inline: auto;
}
.blog-error {
  position: fixed;
  top: 1%;
  left: 0;
  background-color: #fff;
  width: 100%;
}
.label-text-box-wrapper {
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 0.2rem;
}
.all-label-div-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 3rem;
}
.text-area {
  background-color: transparent;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid;
  outline: none;
  resize: none;
  
}
.meta-heading {
  margin: 2rem auto;
  text-align: center;
}
.blog-main-heading {
  font-size: 1.3rem;
  text-align: center;
  padding: 2rem 0px;
}
.blog-heading-text-box {
  width: 60%;
  display: inline-block;
  margin-inline: auto;
}
.delete-btn {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.delete-icon {
  font-size: 1.2rem;
  color: red;
}
.edit-icon {
  color: skyblue;
}
.loder-wrapper {
  min-width: 20rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.images {
  width: 3rem;
  object-fit: contain;
}
.select-box {
  padding: 1rem;
}
.website-query-heading {
  text-align: center;
  font-size: 1.4rem;
  color: #424242;
  padding: 1rem 0px;
  font-weight: 600;
}

.tableHeader {
  background-color: #dedcdc;
  position: sticky;
  top: 0;
}
.prview-wrapper{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;
  background-color: #f6fbff;
  z-index: 23;
}
.preview-btn{
  padding: 0.5rem;
  border: 1px solid gray;
  background-color: #f6fbff;
  border-radius: 5px;
}
.download-btn-wrapper{
  width: 100%;
  margin: 2% 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 4%;
}